import { Link } from "react-router-dom";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
});

export default function ProductCardRowStyleTwo({ className, datas }) {
  const currentDate = new Date();

  const hasSpecialPrice =
    datas.minsp !== null &&
    datas.maxsp !== null &&
    datas.discount_start_date !== null &&
    datas.discount_end_date !== null &&
    currentDate >= new Date(datas.discount_start_date) &&
    currentDate <= new Date(datas.discount_end_date);

  let price;
  let oldPrice;
  let discountPercent;

  if (hasSpecialPrice) {
    if (datas.minsp === datas.maxsp) {
      oldPrice = formatter.format(datas.minp);
      price = formatter.format(datas.minsp);
    } else {
      let p1 = formatter.format(datas.minp);
      let p2 = formatter.format(datas.maxp);
      oldPrice = p1 + "-" + p2;
      let sp1 = formatter.format(datas.minsp);
      let sp2 = formatter.format(datas.maxsp);
      price = sp1 + "-" + sp2;
    }
    discountPercent = Math.round(
      ((datas.minp - datas.minsp) / datas.minp) * 100
    );
  } else {
    if (datas.minp === datas.maxp) {
      price = formatter.format(datas.maxp);
    } else {
      let p1 = formatter.format(datas.minp);
      let p2 = formatter.format(datas.maxp);
      price = p1 + "-" + p2;
    }
  }

  return (
    <div
      data-aos="fade-up"
      className={`product-card-row-two w-full  ${className || ""}`}
    >
      <div className="w-full h-[105px] bg-white border border-primarygray px-5 ">
        <div className="w-full h-full flex space-x-5 justify-center items-center">
          <div className="w-[75px] h-[75px]">
            <img
              src={process.env.REACT_APP_IMAGE_FOLDER + `/` + datas.name}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex-1 h-full flex flex-col justify-center ">
            <Link to={`/single-product/${datas.id}`}>
              <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-1 hover:text-blue-600">
                {datas.p_name}
              </p>
            </Link>

            <p className="price">
              {/*<span className="main-price text-qgray line-through font-600 text-[18px]">*/}
              {/*  {datas.price}*/}
              {/*</span>*/}
              {hasSpecialPrice && (
                <span className="text-l font-400 text-gray-500 line-through ml-2">
                  {oldPrice}
                  <br />
                </span>
              )}
              <span className="offer-price text-qred font-600 text-[18px] ml-2">
                {price}
              </span>
              {hasSpecialPrice && (
                <span className="text-sm font-400 text-green-500 ml-2 mb-4">
                  {discountPercent}% off
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
