import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Arrow from "../../../Helpers/icons/Arrow";
import useCookiesHook from "../../../../hooks/useCookiesHook";
import axios from "axios";

export default function Navbar({ className, type }) {
  const [productCategoryList, setproductCategoryList] = useState([]);

  const { cookies } = useCookiesHook();
  const [categoryToggle, setToggle] = useState(false);
  const [elementsSize, setSize] = useState("0px");
  // const getItems = document.querySelectorAll(`.categories-list li`).length;
  // if (categoryToggle && getItems > 0) {
  //   setSize(`${40 * getItems}px`);
  // }

  // State to manage submenu visibility
  // const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const handler = () => {
    setToggle(!categoryToggle);
  };
  useEffect(() => {
    if (categoryToggle) {
      const getItems = document.querySelectorAll(`.categories-list li`).length;
      if (categoryToggle && getItems > 0) {
        setSize(`${42 * getItems}px`);
      }
    } else {
      setSize(`0px`);
    }
  }, [categoryToggle]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const category = async () => {
      return await axios.get(
        `${process.env.REACT_APP_REDIRECT_URI}/category/getCategoryList`
      );
    };
    category()
      .then((response) => {
        isMounted && setproductCategoryList(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        isMounted = false;
        controller.abort();
      });
  }, []);

  return (
    <div
      className={`nav-widget-wrapper w-full h-[60px] relative z-10 ${
        type === 3 ? "bg-qh3-blue" : "theme-main-bg"
      }  ${className || ""}`}
    >
      <div className="container-x mx-auto h-full">
        <div className="w-full h-full relative">
          <div className="w-full h-full flex justify-between items-center">
            <div className="category-and-nav flex xl:space-x-7 space-x-3 items-center">
              <div className="category w-[270px] h-[53px] bg-white px-5 rounded-t-md mt-[6px] relative">
                <button
                  onClick={handler}
                  type="button"
                  className="w-full h-full flex justify-between items-center"
                >
                  <div className="flex space-x-3 items-center">
                    <span>
                      <svg
                        className="fill-current"
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="14" height="1" />
                        <rect y="8" width="14" height="1" />
                        <rect y="4" width="10" height="1" />
                      </svg>
                    </span>
                    <span className="text-sm font-600 text-qblacktext">
                      All Categories
                    </span>
                  </div>
                  <div>
                    <Arrow
                      width="5.78538"
                      height="1.28564"
                      className="fill-current text-qblacktext"
                    />
                  </div>
                </button>
                {categoryToggle && (
                  <div
                    className="fixed top-0 left-0 w-full h-full z-50"
                    onClick={handler}
                  ></div>
                )}
                <div
                  className="category-dropdown w-full absolute left-0 top-[53px] overflow-hidden z-50"
                  style={{ height: `${elementsSize} ` }}
                >
                  <ul className="categories-list bg-white w-full overflow-y-auto rounded-b-md">
                    {productCategoryList.map((category) => (
                      <li className="category-item" key={category.id}>
                        <div className="relative">
                          <Link
                            key={category.id}
                            to={`/category/${category.id}`}
                            className="flex items-center justify-between w-full px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-xs font-400"
                            onClick={handler}
                          >
                            {category.product_cat_name}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="nav">
                <ul className="nav-wrapper flex xl:space-x-10 space-x-5">
                  <li className="relative">
                    <Link to="/">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>Home</span>
                      </span>
                    </Link>
                  </li>
                  <li className="relative">
                    <Link to="/all-products">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>Shop All</span>
                      </span>
                    </Link>
                  </li>
                  <li className="relative">
                    <Link to="/category/1">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>Mobile Accesories</span>
                      </span>
                    </Link>
                  </li>
                  <li className="relative">
                    <Link to="/category/2">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>Watches & Accessories</span>
                      </span>
                    </Link>
                  </li>
                  <li className="relative">
                    <Link to="/category/4">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>Power Banks</span>
                      </span>
                    </Link>
                  </li>
                  {/* <li className="relative">
                    <Link to="/category/27">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-qblacktext"
                        }`}
                      >
                        <span>Chargers & Adapters</span>
                      </span>
                    </Link>
                  </li>
                  <li className="relative">
                    <Link to="/category/6">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-qblacktext"
                        }`}
                      >
                        <span>Headphones & Earphones</span>
                      </span>
                    </Link>
                  </li> */}
                  {/* <li>*/}
                  {/*    <span*/}
                  {/*        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-white" : "text-qblacktext"*/}
                  {/*        }`}*/}
                  {/*    >*/}
                  {/*        <span>Shop</span>*/}
                  {/*        <span className="ml-1.5 ">*/}
                  {/*            <Arrow className="fill-current" />*/}
                  {/*        </span>*/}
                  {/*    </span>*/}
                  {/*    <div className="sub-menu w-full absolute left-0 top-[60px]">*/}
                  {/*        <div*/}
                  {/*            className="mega-menu-wrapper w-full bg-white p-[30px] flex justify-between items-center "*/}
                  {/*            style={{*/}
                  {/*                minHeight: "295px",*/}
                  {/*                boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",*/}
                  {/*            }}*/}
                  {/*        >*/}
                  {/*            <div className="categories-wrapper flex-1 h-full flex justify-around -ml-[70px]">*/}
                  {/*                <div>*/}
                  {/*                    <div className="category">*/}
                  {/*                        <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">*/}
                  {/*                            Product Category 1*/}
                  {/*                        </h1>*/}
                  {/*                    </div>*/}
                  {/*                    <div className="category-items">*/}
                  {/*                        <ul className="flex flex-col space-y-2">*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3 */}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue" */}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                        Model 1*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3 */}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue" */}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                        Model 2*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3 */}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue" */}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                        Model 3*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3 */}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue" */}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                        Model 4*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                        Model 5*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                        </ul>*/}
                  {/*                    </div>*/}
                  {/*                </div>*/}
                  {/*                <div>*/}
                  {/*                    <div className="category">*/}
                  {/*                        <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">*/}
                  {/*                            Product Category 2*/}
                  {/*                        </h1>*/}
                  {/*                    </div>*/}
                  {/*                    <div className="category-items">*/}
                  {/*                        <ul className="flex flex-col space-y-2">*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 1*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 2*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 3*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 4*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                        </ul>*/}
                  {/*                    </div>*/}
                  {/*                </div>*/}
                  {/*                <div>*/}
                  {/*                    <div className="category">*/}
                  {/*                        <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">*/}
                  {/*                            Product Category 3*/}
                  {/*                        </h1>*/}
                  {/*                    </div>*/}
                  {/*                    <div className="category-items">*/}
                  {/*                        <ul className="flex flex-col space-y-2">*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 1*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 2*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 3*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                            <li>*/}
                  {/*                                <Link to="/all-products">*/}
                  {/*                                    <span*/}
                  {/*                                        className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3*/}
                  {/*                                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                                            : "hover:text-theme hover:border-theme"*/}
                  {/*                                        }`}*/}
                  {/*                                    >*/}
                  {/*                                      Model 4*/}
                  {/*                                    </span>*/}
                  {/*                                </Link>*/}
                  {/*                            </li>*/}
                  {/*                        </ul>*/}
                  {/*                    </div>*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*            <div className="thumbnil w-[348px] h-full">*/}
                  {/*                <div className="w-full h-[235px]">*/}
                  {/*                    <img*/}
                  {/*                        width=""*/}
                  {/*                        src={`${process.env.PUBLIC_URL}/assets/images/mega-menu-thumb.jpg`}*/}
                  {/*                        alt=""*/}
                  {/*                        className="w-full h-full object-contain"*/}
                  {/*                    />*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</li> */}

                  {/* <li className="relative">
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3 ? "text-white" : "text-qblacktext"
                      }`}
                    >
                      <span>FAQ</span>
                      <span className="ml-1.5 ">
                        <Arrow className="fill-current" />
                      </span>
                    </span>
                    <div className="sub-menu w-[220px] absolute left-0 top-[60px]">
                      <div
                        className="w-full bg-white flex justify-between items-center "
                        style={{
                          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      >
                        <div className="categories-wrapper w-full h-full p-5">
                          <div>
                            <div className="category-items">
                              <ul className="flex flex-col space-y-2">
                                <li>
                                  <Link to="/privacy-policy">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${
                                        type === 3
                                          ? "hover:text-qh3-blue hover:border-qh3-blue"
                                          : "hover:text-theme hover:border-theme"
                                      }`}
                                    >
                                      Privacy Policy
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/terms-condition">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${
                                        type === 3
                                          ? "hover:text-qh3-blue hover:border-qh3-blue"
                                          : "hover:text-theme hover:border-theme"
                                      }`}
                                    >
                                      Terms and Conditions
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/faq">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${
                                        type === 3
                                          ? "hover:text-qh3-blue hover:border-qh3-blue"
                                          : "hover:text-theme hover:border-theme"
                                      }`}
                                    >
                                      FAQ
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="/about">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-qblacktext"
                        }`}
                      >
                        <span>About</span>
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                                            <Link to="/blogs">
                                              <span
                                                className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-white" : "text-qblacktext"
                                                  }`}
                                              >
                                                <span>Blog</span>
                                              </span>
                                            </Link>
                                          </li> */}
                  {/* <li>
                    <Link to="/contact">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-qblacktext"
                        }`}
                      >
                        <span>Contact</span>
                      </span>
                    </Link>
                  </li>  */}
                </ul>
              </div>
            </div>
            {/* <div className="become-seller-btn">
              <Link to="/Signup">
                <div
                  className={
                    cookies.login
                      ? "black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer hidden"
                      : "black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer"
                  }
                >
                  <div className="flex space-x-2 items-center">
                    <span className="text-sm font-600">SignUp</span>
                    <span>
                      <svg
                        className="fill-current"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.08984"
                          width="6.94106"
                          height="1.54246"
                          transform="rotate(45 1.08984 0)"
                          fill="white"
                        />
                        <rect
                          x="6"
                          y="4.9082"
                          width="6.94106"
                          height="1.54246"
                          transform="rotate(135 6 4.9082)"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
