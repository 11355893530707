import { Link } from "react-router-dom";
import Compair from "../icons/Compair";
import QuickViewIco from "../icons/QuickViewIco";
import Star from "../icons/Star";
import ThinLove from "../icons/ThinLove";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "LKR",
});

export default function ProductCardRowStyleTwo({ className, datas, type }) {
  const currentDate = new Date();

  const hasSpecialPrice =
    datas.minsp !== null &&
    datas.maxsp !== null &&
    datas.discount_start_date !== null &&
    datas.discount_end_date !== null &&
    currentDate >= new Date(datas.discount_start_date) &&
    currentDate <= new Date(datas.discount_end_date);

  let price;
  let oldPrice;
  let discountPercent;

  if (hasSpecialPrice) {
    if (datas.minsp === datas.maxsp) {
      oldPrice = formatter.format(datas.minp);
      price = formatter.format(datas.minsp);
    } else {
      let p1 = formatter.format(datas.minp);
      let p2 = formatter.format(datas.maxp);
      oldPrice = p1 + "-" + p2;
      let sp1 = formatter.format(datas.minsp);
      let sp2 = formatter.format(datas.maxsp);
      price = sp1 + "-" + sp2;
    }
    discountPercent = Math.round(
      ((datas.minp - datas.minsp) / datas.minp) * 100
    );
  } else {
    if (datas.minp === datas.maxp) {
      price = formatter.format(datas.maxp);
    } else {
      let p1 = formatter.format(datas.minp);
      let p2 = formatter.format(datas.maxp);
      price = p1 + "-" + p2;
    }
  }

  return (
    <Link to={`/single-product/${datas.id}`}>
      <div
        data-aos="fade-left"
        className={`product-row-card-style-one w-full h-[250px] bg-white group relative overflow-hidden ${
          className || ""
        }`}
      >
        <div className="flex space-x-5 items-center w-full h-full lg:p-[30px] sm:p-5 p-2">
          <div className="lg:w-1/2 w-1/3 h-full">
            <img
              src={process.env.REACT_APP_IMAGE_FOLDER + `/` + datas.name}
              alt=""
              className="w-full h-full object-contain"
            />
          </div>
          <div className="flex-1 flex flex-col justify-center h-full">
            <div>
              {/* reviews */}
              {/*<div className="flex space-x-1 mb-3">*/}
              {/*  {Array.from(Array(datas.review), () => (*/}
              {/*    <span key={datas.review + Math.random()}>*/}
              {/*      <Star />*/}
              {/*    </span>*/}
              {/*  ))}*/}
              {/*</div>*/}
              <Link to={`/single-product/${datas.id}`}>
                <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-blue-600">
                  {datas.p_name}
                </p>
              </Link>
              <p className="price mb-[26px]">
                {/*<span className="main-price text-qgray line-through font-600 sm:text-[18px] text-base">*/}
                {/*  {datas.price}*/}
                {/*</span>*/}
                {hasSpecialPrice && (
                  <span className="text-l font-400 text-gray-500 line-through ml-2">
                    {oldPrice}
                    <br />
                  </span>
                )}
                <span className="offer-price text-qred font-600 text-[18px] ml-2">
                  {price}
                </span>
                {hasSpecialPrice && (
                  <span className="text-sm font-400 text-green-500 ml-2 mb-4">
                    {discountPercent}% off
                  </span>
                )}
              </p>
              {/* <Link
              to={`/single-product/${datas.id}`}
              type="button"
              className="w-[110px] h-[30px]"
            >
              <span
                className={type === 3 ? "theme-main-btn" : "theme-main-btn"}
              >
                Select Options
              </span>
            </Link> */}
            </div>
          </div>
        </div>
        {/* quick-access-btns */}
        {/* <div className="quick-access-btns flex flex-col space-y-2 absolute group-hover:right-4 -right-10 top-[30px]  transition-all duration-300 ease-in-out">
          <button>
            <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
              <QuickViewIco />
            </span>
          </button>
          <button>
            <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
              <ThinLove />
            </span>
          </button>
          <Link to="javascript:void(0)">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <Compair />
          </span>
        </Link>
        </div> */}
      </div>
    </Link>
  );
}
