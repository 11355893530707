import Cart from "../../../Cart";
import ThinBag from "../../../Helpers/icons/ThinBag";
import ThinLove from "../../../Helpers/icons/ThinLove";
import ThinPeople from "../../../Helpers/icons/ThinPeople";
import SearchBox from "../../../Helpers/SearchBox";
import { Link } from "react-router-dom";
import useCookiesHook from "../../../../hooks/useCookiesHook";
import UseCartHook from "../../../../hooks/useCartHook";
import { useEffect } from "react";

export default function Middlebar({ className, type }) {
  const { cookies } = useCookiesHook();
  const { cartCount, setCartCount } = UseCartHook();

  useEffect(() => {
    const cartClickCount = localStorage.getItem("cart_list");
    if (cartClickCount !== null) {
      setCartCount(JSON.parse(cartClickCount)[0].length);
    } else {
      setCartCount(0);
    }
  }, [setCartCount]);

  return (
    <div className={`w-full h-[86px] bg-white ${className}`}>
      <div className="container-x mx-auto h-full">
        <div className="relative h-full">
          <div className="flex justify-between items-center h-full">
            <div>
              <Link to="/">
                <img
                  width="200"
                  height="77"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="logo"
                />
              </Link>
            </div>
            {/* <div className="w-[517px] h-[44px]">
                            <SearchBox type={type} className="search-com"/>
                        </div> */}
            <div className="flex space-x-6 items-center">
              {/* <div className="compaire relative">
                            <Link to="/products-compaire">
                              <span>
                                <Compair />
                              </span>
                            </Link>
                            <span
                              className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                                type === 3 ? "bg-qh3-blue text-white" : "theme-main-bg"
                              }`}
                            >
                              2
                            </span>
                          </div> */}
              {/* <div className="favorite relative">
                <Link to="/wishlist">
                  <span>
                    <ThinLove />
                  </span>
                </Link>
                <span
                  className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                    type === 3 ? "bg-qh3-blue text-white" : "theme-main-bg"
                  }`}
                >
                  1
                </span>
              </div> */}
              <div className="cart-wrapper group relative py-4">
                <div className="cart relative cursor-pointer">
                  <Link to="/cart">
                    <span>
                      <ThinBag />
                    </span>
                  </Link>
                  <span
                    className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                      type === 3 ? "bg-qh3-blue text-white" : "theme-main-bg"
                    }`}
                  >
                    {cartCount}
                  </span>
                </div>
                {/* <div className="fixed left-0 top-0 w-full h-full z-40"></div> */}
                {/* hidden group-hover:block" */}
                {/*<Cart*/}
                {/*    type={type}*/}
                {/*    className="absolute -right-[45px] top-11 z-50 hidden group-hover:block"*/}
                {/*/>*/}
              </div>
              {/* <div>
                <Link to={cookies.login ? "/profile" : "/Login"}>
                  <span>
                    <ThinPeople />
                  </span>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
